import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="/web-design-and-development">Website Development & Design</a>
            </li>
            <li>
              <a href="/ecommerce">eCommerce</a>
            </li>
            <li>
              <a href="/social-media">Social Media Managment</a>
            </li>
            <li>
              <a href="/seo-ppc">SEO & PPC</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>need help?</h4>
          <p>
            Would you like to speak to a member of our team for help or advice?
            Send us an email or contact us on one of our social media's.
          </p>
          <h5>Hello@custarddigital.co.uk</h5>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
