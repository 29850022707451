import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
              Contact us anytime using our socials or email. We aim to respond to all enquiries within 24hrs.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              {/* <p>
                66 Broklyn Street, New York
                <br />
                United States of America
              </p> */}
              {/* <p>Phone: Currently Unavailable</p> */}
              <p>
                All Equires:
                 <br></br><a href="#">Hello@custarddigital.co.uk</a>
                 {/* <br></br>
                 Existing Client:<br></br>
                 <a href="#">Maintain@custard.studio</a> */}


              </p>
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="https://www.instagram.com/custarddigital" target="_blank" rel="noopener">
                    <i className="fa fa-instagram"></i>Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/CustardCode" target="_blank" rel="noopener">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" rel="noopener">
                    <i className="fa fa-linkedin"></i>LinkedIn
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" "}
              <a href="#">CustardDigital.co.uk</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
