import React from "react";
import serviceS1 from "@/images/socialmedia2.png";
import serviceS2 from "@/images/services.png";
const SocialMediaContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Social Media Management</h2> 
      <p>
      We create stunning social media content that will grow your brand organically,  
      giving your business credibility and helping to develop a trustworthy identity. 
      Our social media team are experts in creating engaging content to build your social 
      presence and drive traffic to your business. Allow us to manage your social media accounts, 
      create and post engaging content, communicate with your community, and run social media ad campaigns.

      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6"> 
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Soical Media Services</h3>
          <p>
          What services do we offer to grow and improve your social media accounts? 
          </p>
          <ul> 
            <li>
              <i className="fa fa-check-square"></i>Account Management
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Create Engaging Content 
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Data Analysis
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Community Management
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Ad Campaign Management
            </li>
          </ul>
        </div>
      </div>
      <p>
      Use our social media team to build your business a strong brand image and grow your 
      online community. Our team will create expert content designed to engage your community
      and drive organic traffic to your website. Our experts will analyse the data from your 
      social media and inform you of exactly what works for you and what doesn't, meaning you
      waste less time and money on things that don't work.
      </p>
  
    </div>
  );
};

export default SocialMediaContent;
